<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import transApi from "@/apis/transApi";
import indexApi from "@/apis/indexApi";
import storage from '../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../date";
import Navbar2 from "../components/navbar2.vue";

/**
 * Index-1
 */
export default {
  data() {
    return {
      trans: undefined,
      transLoad: 0,
      login: false,
      transForm: {
        status: undefined,
        type: 60,
        pageNum: 1,
        pageSize: 10
      },
      roe: []
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar2
  },
  created() {
    //获取项目列表
    transApi.list(this.transForm, res => {
      this.trans = res.data
      //获取到的数据小于等于全数据时
      if (this.trans.result.length <= this.transForm.pageSize) this.transLoad = -1
    })
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  },
  methods: {
    loadmore(page) {
      //开始加载
      this.transLoad = 1
      this.transForm.pageNum = page
      //获取项目列表
      transApi.list(this.transForm, res => {
        this.transLoad = 0
        Object.assign(this.trans, res.data)
      })
    },
    //用于手机加载
    loadmore2() {
      this.transForm.pageNum++
      //开始加载
      this.transLoad = 1
      let pages = Math.ceil(this.trans.totalCount / this.transForm.pageSize)
      //获取项目列表
      if (pages >= this.transForm.pageNum) {
        transApi.mine(this.transForm, res => {
          res.data.result.unshift(...this.trans.result)
          Object.assign(this.trans, res.data)
          this.transLoad = 0
          if (pages <= this.transForm.pageNum) this.transLoad = -1//结束加载
        })
      } else {
        this.transLoad = -1;
      }
    },
    //类型选择
    select(val) {
      this.transForm.type = val
      this.loadmore(this.transForm.pageNum)
    },
    //重提交
    reSub(id) {
      transApi.reSubmission(id, res => {
        if (res.code != 200) {
          this.$message.error(res.msg)
        } else {
          this.loadmore(this.transForm.pageNum)
        }
      })
    }
  },
  filters: {
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    }
  }
};
</script>

<template>
  <div>
    <!--Shape End-->
    <section class="section" style="padding-bottom: 0px;margin-top: -50px;z-index:1;">
      <div class="container">
        <el-row>
          <h4>Withdraw Records</h4>
        </el-row>
        <el-row v-if="trans==undefined" v-loading="trans==undefined" element-loading-background="rgba(0, 0, 0, 0)">
        </el-row>
        <div v-else class="row">
          <div class="col-24" style="width: 100%;padding: 10px;">
            <div class="table-responsive bg-white shadow rounded mt-4">
              <table class="table mb-0 table-center" v-loading="transLoad==1">
                <thead class="bg-light">
                <tr>
                  <th scope="col" class="text-center">Date</th>
                  <th scope="col" class="text-center">Currency</th>
                  <th scope="col" class="text-center">Status</th>
                  <th scope="col" class="text-center">Amount</th>
                  <th scope="col" class="text-center">USDT</th>
                  <th scope="col" class="text-center">Balance</th>
                  <th scope="col" class="text-center">Type</th>
                  <th scope="col" class="text-center">Wallet Address</th>
                  <!--                    <th scope="col" class="text-center">Submit</th>-->
                </tr>
                </thead>
                <tbody>
                <tr v-for="it in trans.result" :key="it.id">
                  <td class="text-center small" style="font-size: 12px;"><span
                      style="word-spacing: 100vw;text-align: center">{{ it.updatedAt | getTime }}</span></td>
                  <td class="text-center">
                    <a class="forum-title  font-weight-bold text-uppercase" style="color: goldenrod;">
                      {{ it.coinType }}</a>
                  </td>
                  <td class="text-center small">
                    <b-badge v-if="it.status == 0" variant="warning">Pending</b-badge>
                    <b-badge v-else-if="it.status == 1" variant="primary">Paid</b-badge>
                    <b-badge v-else-if="it.status == 2" variant="danger">Cancel</b-badge>
                    <b-badge v-else variant="warning">
                      Invalid
                      <i class="el-icon-question" v-b-popover.hover.bottomleft="it.remark"></i>
                      <p v-if="it.status===-2" style="color: white">  {{ it.remark}}</p>
                    </b-badge>
                  </td>
                  <td class="text-center small" style="font-size: 12px;">{{ it.amount }}&nbsp;{{ it.coinType }}</td>
                  <td class="text-center small" style="font-size: 12px;">${{
                      (it.amount *
                          it.usdtExchangeRate).toFixed(2)
                    }}
                  </td>
                  <td class="text-center small" style="font-size: 12px;">${{ it.balance }}</td>
                  <td class="text-center small">
                    <b-badge v-if="it.type == 50" variant="success">Deposit</b-badge>
                    <b-badge v-else-if="it.type == 60" variant="danger">Withdrawal</b-badge>
                  </td>
                  <td class="text-center small" style="font-size: 12px;">{{ it.address }}</td>
                  <!--                    <td>-->
                  <!--                      <b-button-group v-if="(it.status != 0 && it.status != 1 && it.status != 2)">-->
                  <!--                        <b-button size="sm" variant="primary" @click="reSub(it.id)">Submit-->
                  <!--                        </b-button>-->
                  <!--                      </b-button-group>-->

                  <!--                    </td>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="mt-3 window">
          <div style="display: flex;justify-content: center;">
            <b-pagination v-if="trans!=undefined" :disabled="transLoad == 1" size="lg" v-model="transForm.pageNum"
                          @change="loadmore" :total-rows="trans.totalCount" :per-page="transForm.pageSize"
                          first-text="First"
                          prev-text="Prev"
                          next-text="Next"
                          last-text="Last">
            </b-pagination>
          </div>
        </div>
        <div class="mt-3 phone">
          <div v-if="trans!=undefined" class="d-grid gap-2">
            <b-button v-if="transLoad == 0" block class="btn btn-primary" @click="loadmore2">Load More</b-button>
            <b-button v-else-if="transLoad == 1" block class="btn btn-primary disabled">Loading...</b-button>
            <b-button v-else block class="btn btn-primary disabled">No More</b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
// 表格弧度
.rounded {
  border-radius: 12px !important;
}

.table thead th {
  border-bottom: none;
  border-top: none;
}

// 表头颜色
.table {
  color: rgba(59, 80, 108, 1);
}

// 表格颜色
.table tbody td {
  color: #333333
}

// 表头背景颜色
.bg-light {
  background-color: #f5f7fa !important;
}
</style>
